import type * as Types from '../../schema';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LocationsAutosuggestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LocationsAutosuggestQuery = (
  { __typename?: 'Query' }
  & { dataObjects: (
    { __typename?: 'DataObjects' }
    & { locations: Array<(
      { __typename?: 'Location' }
      & Pick<Types.Location, '_entityType' | 'id' | 'i18nName' | 'name'>
    )>, addressPoiGroups: Array<(
      { __typename?: 'AddressPoiGroup' }
      & Pick<Types.AddressPoiGroup, '_entityType' | 'id' | 'i18nName'>
    )>, regions: Array<(
      { __typename?: 'Region' }
      & Pick<Types.Region, '_entityType' | 'id' | 'i18nName'>
    )> }
  ) }
);


export const LocationsAutosuggestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LocationsAutosuggest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataObjects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_entityType"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"i18nName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"addressPoiGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_entityType"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"i18nName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"regions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_entityType"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"i18nName"}}]}}]}}]}}]} as unknown as DocumentNode<LocationsAutosuggestQuery, LocationsAutosuggestQueryVariables>;