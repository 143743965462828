import { useQuery } from '@urql/vue';
import {
  LocationsAutosuggestDocument,
  type LocationsAutosuggestQuery,
} from '../gql/queries/__generated/LocationsAutosuggest';

export default async () => {
  const { fetching, data, error } = await useQuery<LocationsAutosuggestQuery>({
    query: LocationsAutosuggestDocument,
  });

  const locations = computed(() => {
    return data.value?.dataObjects;
  });

  return { fetching, error, locations };
};
