import dayjs from 'dayjs';
import { Weekday } from '../models/Weekdays';

/**
 * Converts a date string or Date object to the current weekday.
 * @param dateInput - The date string or Date object to convert.
 * @returns The weekday corresponding to the date input.
 */
export function getWeekdayFromDate(dateInput: string | Date): Weekday {
  const date = dayjs(dateInput);
  const weekdayStr = date.format('dddd'); // 'dddd' format returns the full name of the weekday

  switch (weekdayStr) {
    case 'Monday':
      return Weekday.Monday;
    case 'Tuesday':
      return Weekday.Tuesday;
    case 'Wednesday':
      return Weekday.Wednesday;
    case 'Thursday':
      return Weekday.Thursday;
    case 'Friday':
      return Weekday.Friday;
    case 'Saturday':
      return Weekday.Saturday;
    case 'Sunday':
      return Weekday.Sunday;
    default:
      throw new Error(`Invalid weekday: ${weekdayStr}`);
  }
}
