import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { Nullable } from '../models/CustomUtilityTypes';
import { WhlModuleType } from '../models/WhlModuleType';
export default (
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>,
  to?: Nullable<WhlModuleType>
): ComputedRef<string> => {
  return computed(() => {
    const localePath = useLocalePath();
    const whlModuleType = useWhlModuleType();
    const moduleType = to ?? toValue(whlModuleType)!;

    switch (moduleType) {
      case WhlModuleType.Event:
        return localePath(prefixLinkPath('/events/list', widgetConfig));
      case WhlModuleType.Tour:
        return localePath(prefixLinkPath('/tours/list', widgetConfig));
      case WhlModuleType.Poi:
        return localePath(prefixLinkPath('/pois/list', widgetConfig));
      default:
        return localePath('/');
    }
  });
};
