export type PredicateFn = (
  entry: [string, unknown],
  index: number,
  array: [string, unknown][]
) => boolean;
export default (
  obj: object,
  predicate: PredicateFn = ([_key, value]) => !isEmpty(value)
) => {
  return Object.fromEntries(Object.entries(obj ?? {}).filter(predicate));
};
