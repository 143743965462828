import dayjs from 'dayjs';
import type { LocationType } from '../components/Pageheader/Search/Item/Dropdown/Filter/Distance/models';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type {
  AddressbaseFilter,
  EventFilter,
  OpeningHoursFilter,
} from '../gql/schema';
import type { BaseLocation } from '../models/BaseLocation';
import type { BaseTourDifficulty } from '../models/BaseTourDifficulty';
import type { Nullable } from '../models/CustomUtilityTypes';
import { ImxPlatformModules } from '../models/ImxPlatformModules';
import { WhlModuleType } from '../models/WhlModuleType';

export interface SearchModel {
  search: string[];

  dateFrom: string | undefined;
  dateTo: string | undefined;

  daytime: string[];

  onlyFree: true | undefined;
  hasSingleEvent: true | undefined;

  categories: number[];
  criteria: number[];

  duration: [number, number] | undefined;
  length: [number, number] | undefined;
  difficulties: number[];

  locationId: number | undefined;
  locationType: LocationType | undefined;
  locationName: string | undefined;

  latitude: number | undefined;
  longitude: number | undefined;
  radius: number | undefined;
}

async function getLocationName(
  locationId: number | undefined,
  locationType: LocationType | undefined,
  latitude: number | undefined,
  longitude: number | undefined
): Promise<string> {
  const getLocationNameFromPlatform = async () => {
    const locations = await fetchAutosuggestLocations().then(
      (res) => res.locations
    );
    if (isEmpty(locations)) {
      return '';
    }
    let locationArray: BaseLocation[] = [];
    switch (locationType) {
      case 'Location':
        locationArray = (locations.value?.locations || []).filter(
          (loc) => loc._entityType !== undefined
        ) as BaseLocation[];
        break;
      case 'Region':
        locationArray = (locations.value?.regions || []).filter(
          (loc) => loc._entityType !== undefined
        ) as BaseLocation[];
        break;
      case 'AddressPoiGroup':
        locationArray = (locations.value?.addressPoiGroups || []).filter(
          (loc) => loc._entityType !== undefined
        ) as BaseLocation[];
        break;
      default:
        locationArray = [];
    }

    const location = locationArray.find(
      (loc) => loc.id === locationId && loc._entityType === locationType
    );

    return location?.i18nName || '';
  };

  const getLocationNameFromMTK = async () => {
    try {
      return await reverseGeoCodeCoordinates({ latitude, longitude });
    } catch {
      return undefined;
    }
  };

  if (latitude && longitude) {
    return await getLocationNameFromMTK();
  } else if (locationId && locationType) {
    return await getLocationNameFromPlatform();
  } else return '';
}

export const useSearchStore = defineStore('searchStore', () => {
  const validDaytimes = ['morning', 'afternoon', 'evening'];

  const state = reactive<SearchModel>({
    search: [],
    dateFrom: undefined,
    dateTo: undefined,
    daytime: [],
    onlyFree: undefined,
    hasSingleEvent: undefined,
    categories: [],
    criteria: [],
    duration: undefined,
    length: undefined,
    difficulties: [],
    locationId: undefined,
    locationType: undefined,
    locationName: undefined,
    latitude: undefined,
    longitude: undefined,
    radius: undefined,
  });

  const constructFilter = (whlModuleType: Nullable<WhlModuleType>) =>
    computed((): EventFilter | AddressbaseFilter => {
      if (!whlModuleType) {
        return {};
      }

      const reverseDifficultyMapping: Record<BaseTourDifficulty, number[]> = {
        1: [],
        2: [],
        3: [],
      };

      const difficultyMapping =
        useWhlInstanceConfig().value.tour?.difficultyMapping;
      if (difficultyMapping) {
        Object.entries(difficultyMapping).reduce((acc, [key, value]) => {
          if (!acc[value]) {
            acc[value] = [];
          }
          acc[value].push(Number(key));
          return acc;
        }, reverseDifficultyMapping);
      }

      const mapDayTimeToRange = (daytime: string) => {
        switch (daytime) {
          case 'morning':
            return {
              from: dayjs().hour(6).minute(0).second(0).format('HH:mm:ss'),
              to: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
            };
          case 'afternoon':
            return {
              from: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
              to: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
            };
          case 'evening':
            return {
              from: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
              to: dayjs().hour(23).minute(59).second(59).format('HH:mm:ss'),
            };
          default:
            throw new Error(`Unknown daytime value: ${daytime}`);
        }
      };

      const dayTimeRanges = state.daytime?.map(mapDayTimeToRange);

      let userFilter = {};

      switch (whlModuleType) {
        case WhlModuleType.Event: {
          userFilter = {
            fulltext: state.search?.join(' '),
            fromDate: state.dateFrom
              ? dayjs(state.dateFrom).format('YYYY-MM-DD')
              : undefined,
            toDate: state.dateTo
              ? dayjs(state.dateTo).format('YYYY-MM-DD')
              : undefined,
            or: dayTimeRanges.map((daytime) => ({
              startTime: { from: daytime.from, to: daytime.to },
            })),
            pricingFilter: state.onlyFree ? { freeOfCharge: true } : undefined,
            categories: !isEmpty(state.categories)
              ? { oneOf: state.categories }
              : undefined,
            criteria: !isEmpty(state.criteria)
              ? { allOf: state.criteria }
              : undefined,
            eventLocation: {
              regions:
                state.locationType === 'Region'
                  ? {
                      oneOf: [state.locationId].filter((id) => isDefined(id)),
                    }
                  : undefined,
              location:
                state.locationType === 'Location'
                  ? { eq: state.locationId }
                  : undefined,
              group:
                state.locationType === 'AddressPoiGroup'
                  ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
                  : undefined,
            },
            geoFilter: {
              distanceFromPoint:
                state.latitude && state.longitude
                  ? {
                      point: {
                        latitude: state.latitude,
                        longitude: state.longitude,
                      },
                      maxDistance: state.radius ? state.radius : 5000,
                    }
                  : null,
            },
          } satisfies EventFilter;
          break;
        }

        case WhlModuleType.Poi: {
          const openingHoursFilter: OpeningHoursFilter = {
            openAt: undefined,
            openOn: undefined,
            openToday: undefined,
          };
          if (
            !isEmpty([state.dateFrom, state.dateTo], 'some') &&
            state.dateFrom === state.dateTo
          ) {
            const today = dayjs().format('YYYY-MM-DD');
            if (state.dateFrom === today) {
              openingHoursFilter.openToday = true;
            } else {
              openingHoursFilter.openAt = state.dateFrom
                ? dayjs(state.dateFrom).toDate()
                : undefined;
            }
          } else if (!isEmpty([state.dateFrom, state.dateTo], 'some')) {
            openingHoursFilter.openOn = {
              oneOf: [
                getWeekdayFromDate(state.dateFrom!),
                getWeekdayFromDate(state.dateTo!),
              ],
            };
          }

          const openingHoursDayTimeFilter = {
            or: dayTimeRanges.map((daytime) => ({
              openingHours: {
                openAtTimeOfDay: { from: daytime.from, to: daytime.to },
              },
            })),
          };

          userFilter = {
            fulltext: state.search.join(' '),
            productlines: {
              oneOf: state.categories,
              allOf: state.criteria,
            },
            and: [
              { openingHours: openingHoursFilter },
              openingHoursDayTimeFilter,
            ],
            location: state.locationId ? { eq: state.locationId } : undefined,
            group:
              state.locationType === 'AddressPoiGroup'
                ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
                : undefined,
            regions:
              state.locationType === 'Region'
                ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
                : undefined,
            geoFilter: {
              distanceFromPoint:
                state.latitude && state.longitude
                  ? {
                      point: {
                        latitude: state.latitude,
                        longitude: state.longitude,
                      },
                      maxDistance: state.radius || 5000,
                    }
                  : null,
            },
          } satisfies AddressbaseFilter;
          break;
        }

        case WhlModuleType.Tour:
          userFilter = {
            fulltext: state.search?.join(' '),
            productlines: { oneOf: [ImxPlatformModules.TOUR] },
            moduleFilter: {
              tour: {
                categories: {
                  oneOf: state.categories,
                  allOf: state.criteria,
                },
                duration: {
                  gte: state.duration?.at(0),
                  lte: state.duration?.at(1),
                },
                length: {
                  gte: state.length?.at(0),
                  lte: state.length?.at(1),
                },
                difficulties: {
                  oneOf: (state.difficulties as BaseTourDifficulty[]).flatMap(
                    (difficulty) => reverseDifficultyMapping[difficulty] || []
                  ),
                },
              },
            },
            group:
              state.locationType === 'AddressPoiGroup'
                ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
                : undefined,
            location:
              state.locationType === 'Location'
                ? { eq: state.locationId }
                : undefined,
            regions:
              state.locationType === 'Region'
                ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
                : undefined,
          } satisfies AddressbaseFilter;
          break;

        default:
          break;
      }

      return userFilter;
    });

  const getNumberOfFilterItems = (whlModuleType: Nullable<WhlModuleType>) =>
    computed(() => {
      let count = 0;

      let dateCounted = false;
      let locationCounted = false;

      Object.entries(state).forEach(([key, value]) => {
        const isDateKey =
          key === 'dateFrom' || key === 'dateTo' || key === 'daytime';
        const isLocationKey =
          key === 'locationId' ||
          key === 'locationType' ||
          key === 'locationName' ||
          key === 'latitude' ||
          key === 'longitude';

        const isExcludedEventDate =
          whlModuleType === WhlModuleType.Event && isDateKey;

        if (key === 'search' || isExcludedEventDate) {
          return;
        }

        if (isDateKey) {
          if (
            !dateCounted &&
            !isEmpty([state.dateFrom, state.dateTo], 'some')
          ) {
            count++;
            dateCounted = true;
          }
          return;
        }

        if (isLocationKey) {
          if (
            (!isEmpty(
              [state.locationId, state.locationType, state.locationName],
              'some'
            ) ||
              !isEmpty(
                [state.longitude, state.latitude, state.locationName],
                'some'
              )) &&
            !locationCounted
          ) {
            count++;
            locationCounted = true;
          }
          return;
        }

        if (Array.isArray(value)) {
          count += value.filter((item) => !isEmpty(item)).length;
        } else if (!isEmpty(value)) {
          count++;
        }
      });

      return count;
    });

  function pushStateToRoute(
    widgetConfig?: MaybeRef<Nullable<RawWidgetConfigFragment>>
  ): void {
    const { locationName, ...stateToPush } = state;
    const queryToPush = filterObject({
      ...stateToPush,
      search: state.search.join(','),
      categories: state.categories.join(','),
      criteria: state.criteria.join(','),
      duration: isDefined(state.duration)
        ? state.duration.join(',')
        : undefined,
      length: isDefined(state.length) ? state.length.join(',') : undefined,
      difficulties: state.difficulties.join(','),
      daytime: state.daytime.join(','),
    });

    useRouter().push({
      query: queryToPush,
      path: widgetConfig ? toValue(buildLinkToListPage(widgetConfig)) : '#',
    });
  }

  async function readStateFromRoute(): Promise<SearchModel> {
    const { query } = useRoute();

    const dateFrom = query.dateFrom;
    const dateTo = query.dateTo || query.dateFrom;

    if (dateFrom) {
      state.dateFrom = dateFrom as string;
      state.dateTo = dateTo as string;
    }
    if (query.daytime) {
      state.daytime =
        (query.daytime as string)
          ?.split(',')
          .filter((val) => validDaytimes.includes(val))
          .sort(
            (a, b) => validDaytimes.indexOf(a) - validDaytimes.indexOf(b)
          ) ?? [];
    }
    if (query.search) {
      state.search = (query.search as string)?.split(',') ?? [];
    }
    if (query.onlyFree) {
      state.onlyFree = query.onlyFree === 'true' ? true : undefined;
    }
    if (query.hasSingleEvent) {
      state.hasSingleEvent = query.hasSingleEvent === 'true' ? true : undefined;
    }
    if (query.categories) {
      state.categories =
        (query.categories as string)
          ?.split(',')
          .filter((val) => isConvertibleToNumber(val))
          .map(Number) ?? [];
    }
    if (query.criteria) {
      state.criteria =
        (query.criteria as string)
          ?.split(',')
          .filter((val) => isConvertibleToNumber(val))
          .map(Number) ?? [];
    }
    if (query.latitude) {
      state.latitude = isConvertibleToNumber(query.latitude)
        ? Number(query.latitude as string)
        : undefined;
    }
    if (query.longitude) {
      state.longitude = isConvertibleToNumber(query.longitude)
        ? Number(query.longitude as string)
        : undefined;
    }
    if (query.radius) {
      state.radius = isNaN(Number(query.radius as string))
        ? undefined
        : Number(query.radius as string);
    }
    if (query.duration) {
      const duration = (query.duration as string)
        ?.replace(/\s+/g, '')
        .split(',')
        .slice(0, 1)
        .every((part) => !isEmpty(part) && !isNaN(Number(part)))
        ? (query.duration as string)
            .replace(/\s+/g, '')
            .split(',')
            .slice(0, 2)
            .map(Number)
        : undefined;
      state.duration =
        duration?.length === 2 ? (duration as [number, number]) : undefined;
    }
    if (query.length) {
      const length = (query.length as string)
        ?.replace(/\s+/g, '')
        .split(',')
        .slice(0, 1)
        .every((part) => !isEmpty(part) && isConvertibleToNumber(part))
        ? (query.length as string)
            ?.replace(/\s+/g, '')
            .split(',')
            .slice(0, 2)
            .map(Number)
        : undefined;
      state.length =
        length?.length === 2 ? (length as [number, number]) : undefined;
    }
    if (query.difficulties) {
      state.difficulties =
        (query.difficulties as string)?.split(',').map(Number) ?? [];
    }
    if (query.locationType && query.locationId) {
      state.locationId = isConvertibleToNumber(query.locationId)
        ? Number(query.locationId as string)
        : undefined;
      state.locationType = query.locationType as LocationType;
    }
    if (
      (state.locationId && state.locationType) ||
      (state.latitude && state.longitude)
    ) {
      state.locationName = await getLocationName(
        state.locationId,
        state.locationType,
        state.latitude,
        state.longitude
      );
    }

    pushStateToRoute();

    return state;
  }

  function resetState(): void {
    Object.keys(state).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      const value = state[typedKey];

      if (Array.isArray(value)) {
        // For array properties, reset to empty array of same type
        (state[typedKey] as unknown[]) = [];
      } else {
        // For non-array properties, reset to undefined
        (state[typedKey] as undefined) = undefined;
      }
    });

    pushStateToRoute();
  }

  function removeValue(
    key: keyof SearchModel | 'date' | 'location',
    value?: number | string
  ) {
    if (key === 'date') {
      state.dateFrom = '';
      state.dateTo = '';
      pushStateToRoute();
      return;
    }

    if (key === 'search') {
      state.search = [];
      pushStateToRoute();
      return;
    }

    if (key === 'duration' || key === 'length') {
      state[key] = undefined;
      pushStateToRoute();
      return;
    }

    if (key === 'location') {
      state.locationId = undefined;
      state.locationType = undefined;
      state.locationName = undefined;
      state.latitude = undefined;
      state.longitude = undefined;
      pushStateToRoute();
      return;
    }

    const stateValue = state[key];
    if (Array.isArray(stateValue) && value !== undefined) {
      (state[key] as (string | number)[]) = stateValue.filter(
        (v) => v !== value
      );
    } else if (Array.isArray(stateValue)) {
      (state[key] as (string | number)[]) = [];
    } else {
      (state[key] as undefined) = undefined;
    }

    pushStateToRoute();
  }

  function updateState(
    newState: Partial<SearchModel>,
    widgetConfig: Nullable<RawWidgetConfigFragment>
  ): SearchModel {
    Object.keys(newState).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      if (newState[typedKey] !== undefined) {
        (state[typedKey] as unknown) = newState[
          typedKey
        ] as SearchModel[typeof typedKey];
      }
    });

    pushStateToRoute(widgetConfig);

    return state;
  }

  function parseNewState(
    newState: Partial<Record<keyof SearchModel, unknown>>
  ): Partial<SearchModel> {
    const parsedState: Partial<SearchModel> = {};

    Object.keys(newState).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      if (!(typedKey in state)) {
        console.error(`Key ${typedKey} not found in state`);
        return;
      }
      const value = toValue(newState[typedKey]);
      switch (typedKey) {
        case 'search':
          parsedState.search = !isEmpty(value)
            ? (value as string).split(' ')
            : [];
          break;
        case 'dateFrom':
        case 'dateTo':
          parsedState[typedKey] = !isEmpty(value)
            ? dayjs(value as string).format('YYYY-MM-DD')
            : undefined;
          break;
        case 'daytime':
          if (Array.isArray(value)) {
            const validDaytimes = ['morning', 'afternoon', 'evening'];
            parsedState.daytime = (value as string[])
              .filter((time) => validDaytimes.includes(time))
              .sort(
                (a, b) => validDaytimes.indexOf(a) - validDaytimes.indexOf(b)
              );
          } else {
            parsedState.daytime = [];
          }
          break;
        case 'latitude':
        case 'longitude':
        case 'radius':
        case 'locationId':
          parsedState[typedKey] =
            isEmpty(value) || isNaN(Number(value)) ? undefined : Number(value);
          break;
        case 'onlyFree':
        case 'hasSingleEvent':
          parsedState[typedKey] = value === true ? true : undefined;
          break;
        case 'duration':
        case 'length':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as [string, string]).map(Number).every((num) => num === 0)
              ? undefined
              : ((value as [string, string]).map(Number) as [number, number])
            : undefined;
          break;
        case 'difficulties':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as number[]).map(Number)
            : [];
          break;
        default:
          (parsedState[typedKey] as unknown) = value;
      }
    });

    return parsedState;
  }

  function hasAnyValue(exclude: (keyof typeof state)[] = []): boolean {
    return Object.entries(state)
      .filter(([key]) => !exclude.includes(key as keyof typeof state))
      .some(([_, value]) => !isEmpty(value));
  }

  return {
    state,
    constructFilter,
    pushStateToRoute,
    readStateFromRoute,
    resetState,
    removeValue,
    updateState,
    parseNewState,
    hasAnyValue,
    getNumberOfFilterItems,
  };
});
